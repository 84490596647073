module.exports = {
    future: {
        removeDeprecatedGapUtilities: true,
        purgeLayersByDefault: true,
    },
    purge: {
        content: [
            './src/app/components/**/*.vue',
            './src/main.js',
            './src/assets/styles/**/*.scss',
            './drupal/web/themes/lhs/templates/**/*.twig',
            './drupal/web/modules/custom/app/templates/**/*.twig',
        ],
        options: {
            safelist: [],
            blocklist: [],
        },
    },

    theme: {
        colors: {
            transparent: 'transparent',
            current: 'currentColor',
            black: '#000',
            white: '#fff',

            gray: {
                100: '#f2f2f2',
                200: '#dadada',
                400: '#949494',
                450: '#737373',
                500: '#757575',
                600: '#515151',
            },

            brand: '#00ffff',
            green: '#06c92b',
            red: '#ff0000',
        },

        spacing: {
            px: '1px',
            '0': '0',
            '1': '0.25rem',
            '2': '0.5rem',
            '3': '0.75rem',
            '4': '1rem',
            '5': '1.25rem',
            '6': '1.5rem',
            '8': '2rem',
            '10': '2.5rem',
            '12': '3rem',
            '14': '3.5rem',
            '16': '4rem',
            '18': '4.5rem',
            '20': '5rem',
            '22': '5.5rem',
            '24': '6rem',
            '32': '8rem',
            '40': '10rem',
            '48': '12rem',
            '56': '14rem',
            '64': '16rem',
            '72': '18rem',
            '80': '20rem',
            '96': '24rem',
        },

        container: {
            center: true,
        },

        screens: {
            md: '768px',
            lg: '992px',
            xl: '1200px',
        },

        fontFamily: {
            'anton': ['Anton', 'sans-serif'],
            'roboto': ['Roboto', 'sans-serif'],
        },

        extend: {
            width: {
                '3/8': '37.5%',
                '5/8': '62.5%',
                'column1': '296px',
                'column2': '224px',
                'column3': '456px',
                'column4': '224px',
                'video-9-16': '480px',
            },

            maxWidth: {
                'article': '40.625rem',
                'video-9-16': '368px',
                'video-16-9': '480px',
                'special': '65.25rem'
            },
            height: {
                '222': '222px',
                '340': '340px',
                'video-9-16': '654px',
                'video-16-9': '270px',
            },
            borderWidth: {
                '1': '1px',
                '10': '10px',
            },

            boxShadow: {
                '3xl': '0 35px 60px -15px rgba(0, 0, 0, 1)',
                'hard': '-3px 3px 0px 0px black'
            },

            fontSize: {
                '1xs': '0.875rem',
                '2xs': '0.5rem',
            },
            borderRadius: {
                '5': '5px',
            },
        },
    },

    variants: {},

    plugins: [
        require('@tailwindcss/aspect-ratio'),
    ]
};
