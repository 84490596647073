<template>
    <div
        class="flex flex-col items-center grid-container container md:mt-10 min-h-screen md:min-h-full"
         :class="selectedAnswer !== null ? 'mb-0' : 'mb-10'"
        :style="{ paddingBottom: (selectedAnswer !== null && !quizFinished) ? '180px' : '0' }"
    >
        <button @click="goBack()"
                class="absolute -top-1 right-0 flex md:hidden mt-5 mr-4">
            <img src="~@/static/images/icons/cross.svg" alt="Fermer">
        </button>
        <div v-if="!quizFinished"
             class="w-full max-w-screen-lg flex items-center justify-center gap-4 text-sm mb-6 mt-5">
            <div class="progress-bar-container h-1 bg-gray-200 relative rounded-full">
                <div class="h-full bg-black transition-all absolute top-0 left-0 rounded-full"
                     :style="{ width: `${progress}%` }"></div>
            </div>
            <span class="text-lg font-semibold text-center">{{
                    currentIndex + 1
                }} sur {{ quizData.quiz.questions.length }}</span>
        </div>

        <div v-if="quizFinished">
            <div class="flex flex-col w-full md:w-4/5 lg:w-2/3 mx-auto md:flex-row items-stretch gap-6">
                <div class="flex-1 text-center md:text-left w-full md:w-1/2">
                    <h2 class="text-5xl text-center mt-16 md:mt-0">{{
                            correctAnswers
                        }}/{{ quizData.quiz.questions.length }}
                        <br>
                        {{
                            isSuccess ? quizData.result.valid.title : quizData.result.not_valid.title
                        }}
                    </h2>
                    <p class="mt-4 font-bold leading-8 text-lg text-center">{{
                            isSuccess ? quizData.result.valid.description : quizData.result.not_valid.description
                        }}</p>
                    <div class="hidden md:flex flex-col gap-2 w-full md:w-3/5 mx-auto mt-4">
                        <button @click="restartQuiz" class="btn btn--ghost-black">Recommencer le
                            test
                        </button>
                    </div>
                </div>
                <div class="w-full md:w-1/2">
                    <img
                        v-if="isSuccess ? quizData.result.valid.image : quizData.result.not_valid.image"
                        :src="isSuccess ? quizData.result.valid.image : quizData.result.not_valid.image"
                        class="h-full w-full object-cover rounded-lg" alt="">
                    <div class="flex flex-col gap-2 w-full md:w-3/5 mx-auto mt-4 md:hidden">
                        <button @click="goBack" class="btn btn--black flex md:hidden">Aller sur
                            tilt
                        </button>
                        <button @click="restartQuiz" class="btn btn--ghost-black">Recommencer le
                            test
                        </button>
                    </div>
                </div>

            </div>

            <div v-if="quizData.recommendations.length"
                 class="text-white md:border-b-2 md:border-white bg-black mt-6 absolute left-0 right-0 bottom-0 mx-auto flex flex-col items-start gap-2 z-50">
                <div class="grid-container container mt-6 mb-4 md:mt-8">
                    <h3>Quiz recommandés</h3>
                    <div class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4 mb-0 md:mb-10 md:mt-6">
                        <a v-for="quiz in quizData.recommendations" :key="quiz.url" :href="quiz.url"
                           class="no-underline flex items-center gap-4 justify-between md:justify-start">
                            <div class="flex items-center gap-4">
                                <img :src="quiz.image" alt="Quiz Image"
                                     class="w-16 h-16 object-cover rounded-md">
                                <span>{{ quiz.title }}</span>
                            </div>
                            <img src="~@/static/images/icons/arrow-right-white.svg" alt="" class="w-4 h-4">
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="!quizFinished" class="flex flex-col md:flex-row gap-6 md:gap-14 mx-auto">
            <div
                class="image-container flex justify-center items-center rounded-lg overflow-hidden"
                v-if="currentQuestion.image_desktop"
            >
                <img :src="currentQuestion.image_desktop"
                     alt="Image question" class="w-full h-full object-cover">
            </div>

            <div class="flex flex-col items-center" :class="currentQuestion.image_desktop ? 'md:items-start' : ''">
                <p class="text-gray-450 text-base md:text-lg text-center md:text-left font-bold mb-2">
                    {{ quizData.quiz.title }}</p>
                <h2 class="font-anton text-2xl font-normal mb-6">{{ currentQuestion.question }}</h2>
                <div class="flex flex-col items-stretch gap-3 w-4/5">
                    <button v-for="(answer, index) in currentQuestion.answers"
                            :key="index"
                            @click="selectAnswer(index)"
                            :disabled="selectedAnswer !== null"
                            class="border-solid border-black border-1 rounded-5 font-anton text-xl font-normal leading-9 shadow-hard bg-white relative px-10"
                            :class="{
                                'correct': selectedAnswer === index && answer.is_correct,
                                'incorrect': selectedAnswer === index && !answer.is_correct,
                                'cursor-default': selectedAnswer !== null
                            }">
                        {{ answer.text }}
                        <img v-if="selectedAnswer !== null && answer.is_correct"
                             class="absolute right-2 top-1/2 transform -translate-y-1/2"
                             src="~@/static/images/icons/check-green.svg" alt="Correct">
                        <img v-if="selectedAnswer !== null && !answer.is_correct"
                             class="absolute right-2 top-1/2 transform -translate-y-1/2"
                             src="~@/static/images/icons/cross-red.svg" alt="Incorrect">
                    </button>
                </div>
            </div>
        </div>
        <div v-if="selectedAnswer !== null && !quizFinished"
             class="absolute left-0 right-0 bottom-0 text-white bg-black md:border-b-2 md:border-white p-4 mt-6 flex flex-col items-center gap-2 z-50"
        >
            <div class="flex space-x-2 items-center justify-center">
                <img v-if="isCorrect"
                     src="~@/static/images/icons/check-green.svg" alt="Correct">
                <img v-else
                     src="~@/static/images/icons/cross-red.svg" alt="Incorrect">
                <h3 class="text-2xl font-normal capitalize font-anton">{{
                        isCorrect ? 'vrai' : 'faux'
                    }}</h3>
            </div>
            <p v-if="(isCorrect && currentQuestion.true_text?.length) || (!isCorrect && currentQuestion.false_text?.length)"
                class="text-base font-medium text-left md:text-center leading-6">
                {{ isCorrect ? currentQuestion.true_text : currentQuestion.false_text}}</p>
            <button v-if="!quizFinished" @click="nextQuestion"
                    class="btn btn--ghost-white mt-2 self-center md:self-auto">
                {{ currentIndex === quizData.quiz.questions.length - 1 ? 'Voir le résultat' : 'Question suivante' }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "QuizApp",
    props: {
        quizData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            currentIndex: 0,
            selectedAnswer: null,
            correctAnswers: 0,
            progress: 10,
            footerHeight: 0,
        };
    },
    computed: {
        currentQuestion() {
            return this.quizData.quiz.questions[this.currentIndex] || {
                question: "",
                answers: [],
                image_desktop: null,
                true_text: "",
                false_text: ""
            };
        },
        isCorrect() {
            return this.selectedAnswer !== null && this.currentQuestion.answers[this.selectedAnswer]?.is_correct;
        },
        quizFinished() {
            return this.currentIndex >= this.quizData.quiz.questions.length;
        },
        isSuccess() {
            return this.correctAnswers >= Math.ceil(this.quizData.quiz.questions.length * 0.7); // 70% de bonnes réponses
        }
    },
    methods: {
        selectAnswer(index) {
            if (this.selectedAnswer === null) {
                this.selectedAnswer = index;
                if (this.currentQuestion.answers[index].is_correct) {
                    this.correctAnswers++;
                }
            }
        },
        nextQuestion() {
            if (this.currentIndex < this.quizData.quiz.questions.length - 1) {
                this.currentIndex++;
                this.selectedAnswer = null;
                this.progress = ((this.currentIndex + 1) / this.quizData.quiz.questions.length) * 100;
            } else {
                this.currentIndex++;
            }
        },
        restartQuiz() {
            this.currentIndex = 0;
            this.selectedAnswer = null;
            this.correctAnswers = 0;
            this.progress = 0;
        },
        goBack() {
            window.location.href = '/';
        },
        detectScreenSizeChange() {
            if (window.innerWidth < 768) {
                document.querySelector('header').style.display = 'none';
                document.querySelector('footer').style.display = 'none';
                document.querySelector('#block-header').style.display = 'none';
                document.querySelector('.breadcrumb--overlay').style.display = 'none';
            } else {
              document.querySelector('.breadcrumb--overlay').style.display = 'none';
                document.querySelector('header').style.display = 'block';
                document.querySelector('footer').style.display = 'block';
                document.querySelector('#block-header').style.display = 'block';
            }
        }
    },
    mounted() {
        this.detectScreenSizeChange();
        window.addEventListener('resize', this.detectScreenSizeChange);
    }
};
</script>
<style>
.image-container {
    width: 344px;
    height: 161px;
    @media screen and (min-width: 768px) {
        height: 321px;
    }
}

.progress-bar-container {
    min-width: 80px;
}
/* This is a workaround to prevent the answer box from being positioned
 regarding the article instead of the main */
article.contextual-region.node--type-quiz.node--view-mode-full {
    position: static !important;
}
</style>
