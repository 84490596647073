<template>
    <div class="header-nav relative pt-16 md:pt-24">
        <header
            class="header-nav__banner fixed top-0 z-20 inset-x-0 w-full h-16 md:h-24 bg-white shadow-md">
            <div class="flex justify-between items-center h-full px-4 md:px-8">
                <a :href="homepageUrl" title="Accueil">
                    <img src="~@/static/images/brand/logo-tilt-black.svg"
                         :alt="content.logoAlt ? content.logoAlt.text : 'Tilt logo'"
                         class="header-nav__logo">
                </a>

                <p class="hidden md:flex">
                    <span class="bg-black text-white px-1 header-nav__baseline">
                        {{ content.baseline.bgBlack }}
                    </span>
                    <span class="bg-brand px-1 header-nav__baseline">
                        {{ content.baseline.bgBlue }}
                    </span>
                </p>

                <div class="flex items-center">
                    <a href="/jagis" class="btn btn--ghost-black md:mr-6">J'agis</a>

                    <header-search-form class="ml-4 md:ml-0 md:mr-6"></header-search-form>

                    <button
                        class="hidden md:block rounded-full focus:ring focus:ring-brand focus:outline-none"
                        @click="isMenuOpened = true">
                        <img src="~@/static/images/icons/burger.svg" alt="Menu">
                    </button>
                </div>
            </div>

            <div class="header-nav__scroll-indicator absolute top-full h-2 bg-black"
                 :style="`width: ${scrollPercent}%;`"/>
        </header>

        <button
            class="header-nav__burger-mobile fixed z-10 right-4 bottom-4 md:hidden shadow-3xl rounded-full"
            @click="isMenuOpened = true">
            <img src="~@/static/images/icons/burger.svg" alt="Menu">
        </button>

        <fade>
            <menu-overlay v-if="isMenuOpened" @close="isMenuOpened = false">
                <div class="flex flex-col w-full h-full p-4 md:p-20 md:pt-32 overflow-y-auto no-scrollbar">
                    <div class="flex flex-col lg:flex-row lg:flex-1 lg:space-x-6 w-full">
                        <!-- Column 1 -->
                        <div class="flex flex-col w-full lg:w-column1 py-4 ">
                            <div class="header-nav__column1">
                                <div class="flex items-center space-x-2">
                                    <div v-if="content.column1.pictogram">
                                        <img :src="content.column1.pictogram.url" alt=""
                                             class="header-nav__pictogram" width="14" height="14">
                                    </div>
                                    <span class="header-nav__column-title font-roboto font-normal no-underline"
                                        :style="{ color: content.column1.color }">
                                        {{ content.column1.title }}
                                    </span>
                                </div>
                                <ul class="header-nav__tags list-none flex flex-wrap mt-4 md:mt-6">
                                    <li v-for="(tag, index) in content.column1.tags"
                                        :key="'tag-' + index" class="mr-2 mb-2">
                                        <a :href="tag.url"
                                           :class="{'block border border-black rounded-md p-2 text-base text-black text-center font-medium md:text-xl no-underline': true,
                                           'header-nav__active-tag': currentPage(tag.url)}">
                                            {{ tag.name }}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <!-- Column 2-->
                        <div class="flex flex-col w-full lg:w-column2 py-4 ">
                            <span class="header-nav__column-title font-roboto font-normal">
                                {{ content.column2.title }}
                            </span>
                            <ul class="list-none mt-4 md:mt-6 flex flex-col space-y-4">
                                <li v-for="(thematic, index) in content.column2.thematics"
                                    :key="'thematic-' + index">
                                    <a :href="thematic.url"
                                       class="header-nav__link font-medium text-base md:text-2xl no-underline">
                                        {{ thematic.name }}
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <!-- Column 3 -->
                        <div class="flex flex-col w-full lg:w-column3 py-4">
                            <span class="header-nav__column-title font-roboto font-normal">
                                {{ content.column3.title }}
                            </span>
                            <div class="rubrics-grid flex flex-wrap basis-1/3 gap-2 mt-4 md:mt-6">
                                <div
                                    v-for="(rubric, index) in content.column3.rubrics"
                                    :key="'rubric-' + index"
                                    class="rubric-card flex justify-center items-center w-24 h-16 md:w-32 md:h-20 rounded-lg"
                                >
                                    <a :href="rubric.url" class="w-full h-full no-underline flex justify-center items-center text-center">
                                        <img
                                            v-if="rubric.menu_image_desktop"
                                            :src="rubric.menu_image_desktop.url"
                                            :alt="rubric.name"
                                            class="hidden md:block w-full h-auto"
                                        >
                                        <img
                                            v-if="rubric.menu_image_mobile"
                                            :src="rubric.menu_image_mobile.url"
                                            :alt="rubric.name"
                                            class="block md:hidden w-full h-auto"
                                        >
                                        <span v-if="!rubric.menu_image_desktop && !rubric.menu_image_mobile">{{ rubric.name }}</span>
                                    </a>
                                </div>
                            </div>
                            <div class="mt-6">
                                <a
                                    v-if="content.column3.link_title && content.column3.link_url"
                                    :href="content.column3.link_url"
                                    class="header-nav__link font-medium text-base flex items-center gap-2 no-underline"
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                        <rect width="16" height="16" rx="8" fill="black"/>
                                        <path d="M8 5V11" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M5 8H11" stroke="white" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                    {{ content.column3.link_title }}
                                </a>
                            </div>
                        </div>
                        <!-- Column 4 -->
                        <div class="flex flex-col w-full lg:w-column4 py-4">
                            <span v-if="content.column4.title" class="header-nav__column-title font-roboto font-normal">
                                {{ content.column4.title }}
                            </span>
                            <ul class="list-none mt-4">
                                <li v-for="(link, index) in content.column4.links" :key="'link-' + index" class="mt-2">
                                    <a :href="link.url" class="header-nav__link font-medium text-base md:text-2xl no-underline">
                                        {{ link.title }}
                                    </a>
                                </li>
                            </ul>
                            <div class="mt-4">
                                <ul class="list-none flex items-center space-x-6">
                                    <li v-for="(social, index) in content.socialNetworks" :key="'social-' + index" class="w-6 h-6 md:w-8 md:h-8 overflow-hidden">
                                        <a :href="social.url" :title="social.name" target="_blank" rel="nofollow">
                                            <img :src="social.picto?.url" :alt="social.name" class="w-full h-full object-contain">
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </menu-overlay>
        </fade>
    </div>
</template>

<script>
import Fade from "@/app/transitions/Fade";
import MenuOverlay from "@/app/components/MenuOverlay";
import HeaderSearchForm from "@/app/components/HeaderSearchForm";
import {theme} from "../../../tailwind.config";

export default {
    name: "HeaderNav",
    computed: {
        theme() {
            return theme
        }
    },

    props: {
        homepageUrl: {
            type: String,
            default: null,
        },

        content: {
            type: Object,
            required: true,
        },
    },

    components: {
        Fade,
        MenuOverlay,
        HeaderSearchForm,
    },

    data() {
        return {
            isMenuOpened: false,
            scrollPercent: 0,
        }
    },

    mounted() {
        window.addEventListener('scroll', this.scrollHandler);
    },

    destroyed() {
        window.removeEventListener('scroll', this.scrollHandler);
    },

    methods: {
        scrollHandler() {
            this.scrollPercent = 100 * window.scrollY / (document.documentElement.scrollHeight - window.innerHeight);
        },
        currentPage(val) {
            return val === window.location.pathname;
        }
    }
}
</script>

<style scoped lang="scss">
.header-nav {
    &__scroll-indicator {
        &::after {
            @apply absolute inset-y-0 left-full w-4 h-2 bg-brand;
            content: '';
            margin-left: 0.625rem;
        }
    }
    &__baseline {
        @apply font-anton font-normal;
        font-size: 1.2625rem;
        line-height: 1.11;
    }

    &__logo {
        width: 3.9375rem;
        height: 2.5rem;
        @screen md {
            width: 6.0625rem;
            height: 3.9375rem;
        }
    }

    &__emoji {
        font-size: 1.125rem;
        line-height: 1.33;
        @screen md {
            font-size: 4rem;
            line-height: normal;
        }
    }

    &__column-title {
        font-size: 13px;
        line-height: 16px;
        color: #737373;
        @screen md {
            font-size: 13px;
            line-height: 16px;
        }
    }

    &__link {
        line-height: 1.5;
        @screen md {
            line-height: 0.85;
        }
    }

    &__active-tag {
        background-color: #E6E6E6;
    }
}

body.toolbar-horizontal.toolbar-tray-open .header-nav .header-nav__banner {
    top: 79px;
}

body.toolbar-vertical .header-nav .header-nav__banner,
body.toolbar-horizontal .header-nav .header-nav__banner {
    top: 39px;
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}
.no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
}
</style>
