<template>
    <div @click.self="$emit('close')" class="bg-black bg-opacity-50 h-screen fixed inset-0 z-20">
        <div class="menu-overlay bg-white w-screen px-4 md:px-0 pt-12 md:pt-0 relative">
            <a href="/" title="Accueil"
               class="absolute top-0 right-left flex flex-col md:flex-row mt-4 ml-8 menu-overlay__nav">
                <img src="~@/static/images/brand/logo-tilt-black.svg"
                     alt="Tilt logo"
                     class="menu-overlay__logo">
            </a>
            <button
                class="absolute top-0 right-0 flex flex-col md:flex-row mt-6 mr-6 menu-overlay__nav"
                @click="$emit('close')">
                <img src="~@/static/images/icons/cross.svg" alt="Fermer">
            </button>
        <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "MenuOverlay"
}
</script>

<style scoped lang="scss">
.menu-overlay {
    @apply h-screen md:h-auto;

    &__logo {
        width: 3.9375rem;
        height: 2.5rem;
        @screen lg {
            width: 6.0625rem;
            height: 3.9375rem;
        }
    }
}

body.toolbar-horizontal.toolbar-tray-open .header-nav .menu-overlay .menu-overlay__nav {
    top: 79px;
}
body.toolbar-vertical .header-nav .menu-overlay,
body.toolbar-horizontal .header-nav .menu-overlay a {
    top: 39px;
}
</style>
